import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const Heading = ({ children, classes, level }) => {
	switch (level) {
		case 1:
			return <h1 className={classes}>{children}</h1>;
		case 2:
			return <h2 className={classes}>{children}</h2>;
		default:
			return <div className={classes}>{children}</div>;
	}
};

const HeadTitle = ({ text, url, align, theme, level, size, className }) => {
	const headTitleCx = cx(
		styles.HeadTitle,
		styles[`HeadTitle_size_${size}`],
		styles[`HeadTitle_align_${align}`],
		styles[`HeadTitle_theme_${theme}`],
		className
	);

	return (
		<Heading classes={headTitleCx} level={level}>
			{url ? (
				<a href={url} className={styles.HeadTitle__Link}>
					{text}
					<Icon
						icon="chevronRight"
						className={styles.HeadTitle__Icon}
						aria-hidden="true"
					/>
				</a>
			) : (
				<span className={styles.HeadTitle__Text}>{text}</span>
			)}
		</Heading>
	);
};

Heading.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.string,
	level: PropTypes.number
};

HeadTitle.propTypes = {
	text: PropTypes.string.isRequired,
	url: PropTypes.string,
	theme: PropTypes.oneOf(['live']),
	align: PropTypes.oneOf(['center']),
	size: PropTypes.oneOf(['medium', 'default', 'large']),
	className: PropTypes.string,
	level: PropTypes.oneOf([1, 2])
};

HeadTitle.defaultProps = {
	size: 'default'
};

export default HeadTitle;
