import _ from 'underscore';
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { LayoutBlock } from 'components/Layout';
import BaseSlider from 'components/sliders/BaseSlider';
import Container from 'components/Container';
import Section from 'components/Section';

import { SCREEN_BREAKPOINTS } from 'utils/helpers/constants';
import { useTabNavigation } from 'hooks/useTabNavigation';

const SimilarEntities = memo(
	({ Card, cardParams, entities, isPortraitCard, title, url, ariaLabel }) => {
		const [swiper, setSwiper] = useState(null);

		const getSwiper = (swiperInstance) => {
			setSwiper(swiperInstance);
		};

		const sliderParams = useMemo(
			() => ({
				slidesPerView: 'auto',
				spaceBetween: 8,
				breakpoints: {
					[SCREEN_BREAKPOINTS.tabletLarge]: {
						slidesPerView: isPortraitCard ? 6 : 4,
						spaceBetween: 24
					},
					[SCREEN_BREAKPOINTS.desktopMedium]: {
						slidesPerView: isPortraitCard ? 7 : 5,
						spaceBetween: 24
					}
				}
			}),
			[isPortraitCard]
		);

		const scrollToSlideOnFocus = (event) => {
			const swiperSlide = event.target.closest('.swiper-slide');
			const indexSlide = [...swiperSlide.parentElement.children].indexOf(
				swiperSlide
			);

			if (swiper && indexSlide) {
				swiper.slideTo(indexSlide);
			}
		};

		const {
			firstListItemRef,
			handleBlur,
			handleFocus,
			handleKeyDown,
			listItemTabIndex
		} = useTabNavigation();

		const renderSlides = useMemo(
			() => [
				..._(entities).map((entity, index) => (
					<Card
						key={entity._id}
						entity={entity}
						tabIndex={listItemTabIndex}
						cardRef={index === 0 ? firstListItemRef : null}
						onFocus={scrollToSlideOnFocus}
						{...cardParams}
					/>
				))
			],
			[firstListItemRef, listItemTabIndex]
		);

		return (
			<LayoutBlock
				theme="light"
				spacing="similar"
				ariaLabel={ariaLabel}
				noPrint
				onFocus={handleFocus}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
				tabIndex={ariaLabel ? 0 : null}
			>
				<Section
					title={title || 'Смотрите также'}
					url={url}
					theme="similar"
					titleLevel={2}
				>
					<Container width="wideSlider">
						<BaseSlider
							theme="full"
							params={sliderParams}
							getSwiper={getSwiper}
							isNewDesign
						>
							{renderSlides}
						</BaseSlider>
					</Container>
				</Section>
			</LayoutBlock>
		);
	}
);

SimilarEntities.propTypes = {
	Card: PropTypes.func.isRequired,
	cardParams: PropTypes.object,
	entities: PropTypes.arrayOf(PropTypes.object).isRequired,
	isPortraitCard: PropTypes.bool,
	title: PropTypes.string,
	url: PropTypes.string,
	ariaLabel: PropTypes.string
};

export default SimilarEntities;
