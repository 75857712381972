import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import cx from 'classnames';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import _ from 'underscore';
import {
	EntityPropType,
	MetaPropType,
	NavigationPropType,
	PromoBlockPropType,
	RubricPropType
} from 'propTypes/common';

import CookieNotify from 'components/CookieNotify';
import GlobalPromoBlock from 'components/GlobalPromoBlock';
import OnlyClient from 'components/OnlyClient';
import TopPromoBlock from 'components/promoBlocks/TopPromoBlock';
import { BodyScrollLockProvider } from 'contexts/BodyScrollLockContext';

import { getCanonicalUrl } from 'utils/helpers/url';
import useGlobalElementRef from 'hooks/useGlobalElementRef';

import FabContainer from './components/FabContainer';
import Footer from './components/Footer';
import Header from './components/Header';
import Meta from './components/Meta';
import SvgIcons from './components/SvgIcons';
import ThematicSvgIcons from './components/ThematicSvgIcons';

import styles from './styles.module.scss';

const {
	publicRuntimeConfig: { services }
} = getConfig();

const Layout = ({
	children,
	title,
	meta,
	rubric,
	menu,
	entity,
	entityType,
	noIndex,
	mainClassName,
	globalPromoBlock,
	topPromoBlock,
	preloadImageSrc,
	progressBarContentRef
}) => {
	const nameCookieTopPromoBlock = topPromoBlock
		? `topPromoBlockClosedId${topPromoBlock._id}`
		: null;
	const [cookies] = useCookies([nameCookieTopPromoBlock]);
	const [showTopPromoBlock, setShowTopPromoBlock] = useState(false);
	const { asPath: currentPath } = useRouter();
	const layoutRef = useGlobalElementRef('layout');
	const contentRef = useRef(null);
	const topPromoBlockRef = useRef(null);
	const customMeta = meta || (rubric && rubric.meta);
	const canonicalUrl = getCanonicalUrl({ entity, entityType, currentPath });

	useEffect(() => {
		if (!_.isEmpty(topPromoBlock) && nameCookieTopPromoBlock) {
			setShowTopPromoBlock(cookies[nameCookieTopPromoBlock] !== true);
		}
	}, [cookies, nameCookieTopPromoBlock]);

	return (
		<>
			<Head>
				<title>
					{title ||
						(customMeta && customMeta.title) ||
						'Культура.РФ. Портал культурного наследия, традиций народов России'}
				</title>

				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="format-detection" content="telephone=no" />
				{noIndex && <meta name="robots" content="noindex, follow" />}
				<meta
					name="proculture-verification"
					content={services.eipsk.siteVerification}
				/>
				<meta name="yandex-verification" content={services.yandex.webmaster} />
				<meta
					name="google-site-verification"
					content={services.google.searchConsoleKey}
				/>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/favicons/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicons/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="194x194"
					href="/favicons/favicon-194x194.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="192x192"
					href="/favicons/android-chrome-192x192.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicons/favicon-16x16.png"
				/>
				<link rel="manifest" href="/favicons/site.webmanifest" />
				<link
					rel="mask-icon"
					href="/favicons/safari-pinned-tab.svg"
					color="#7a7e7f"
				/>
				<link rel="canonical" href={canonicalUrl} />
				<meta name="msapplication-TileColor" content="#7a7e7f" />
				<meta
					name="msapplication-config"
					content="/favicons/browserconfig.xml"
				/>
				<meta name="theme-color" content="#ffffff" />

				{preloadImageSrc && (
					<link rel="preload" as="image" href={preloadImageSrc} />
				)}
			</Head>
			{(customMeta || rubric) && (
				<Meta
					meta={customMeta}
					rubric={rubric}
					entity={entity}
					entityType={entityType}
				/>
			)}

			<BodyScrollLockProvider>
				<div ref={layoutRef} className={styles.Layout}>
					{showTopPromoBlock && (
						<div ref={topPromoBlockRef}>
							<TopPromoBlock
								entity={topPromoBlock}
								nameCookie={nameCookieTopPromoBlock}
							/>
						</div>
					)}
					<Header
						menu={menu}
						hasTopPromoBlock={showTopPromoBlock}
						progressBarContentRef={progressBarContentRef}
					/>
					<main className={cx(styles.Layout__Main, mainClassName)}>
						{globalPromoBlock && <GlobalPromoBlock {...globalPromoBlock} />}
						<div className={styles.Layout__Content} ref={contentRef}>
							<OnlyClient>
								<FabContainer
									entity={entity}
									entityType={entityType}
									parentContainerRef={contentRef}
								/>
							</OnlyClient>
							{children}
						</div>
					</main>
					<Footer />
					<OnlyClient>
						<CookieNotify />
					</OnlyClient>
				</div>
			</BodyScrollLockProvider>

			<SvgIcons />
			<ThematicSvgIcons />
		</>
	);
};

Layout.propTypes = {
	title: PropTypes.string,
	menu: NavigationPropType.isRequired,
	meta: MetaPropType,
	children: PropTypes.node.isRequired,
	rubric: RubricPropType,
	entity: EntityPropType,
	entityType: PropTypes.string,
	noIndex: PropTypes.bool,
	mainClassName: PropTypes.string,
	preloadImageSrc: PropTypes.string,
	globalPromoBlock: PromoBlockPropType,
	topPromoBlock: PromoBlockPropType,
	progressBarContentRef: PropTypes.shape({
		current: PropTypes.object
	})
};

Layout.defaultProps = {
	noIndex: false
};

const LayoutBlock = forwardRef(
	(
		{
			children,
			theme,
			spacing,
			className,
			gutter,
			ariaLabel,
			noPrint,
			onFocus,
			onBlur,
			onKeyDown,
			tabIndex = -1
		},
		ref
	) => {
		const blockCx = cx(
			styles.Layout__Block,
			styles[`Layout__Block_theme_${theme}`],
			styles[`Layout__Block_spacing_${spacing}`],
			styles[`Layout__Block_gutter_${gutter}`],
			{
				[styles.Layout__Block_spacing_inner]: !!theme && !spacing,
				[styles.Layout__Block_spacing_default]: !theme && !spacing && !gutter,
				[styles.Layout__Block_noPrint]: noPrint
			},
			className
		);

		return (
			<div
				ref={ref}
				className={blockCx}
				aria-label={ariaLabel}
				// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
				tabIndex={tabIndex}
				{...{ onFocus, onBlur, onKeyDown }}
			>
				{children}
			</div>
		);
	}
);

LayoutBlock.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	theme: PropTypes.oneOf(['dark', 'light', 'pushkinsCard', 'tertiaryDark']),
	spacing: PropTypes.oneOf(['innerSmall', 'similar']),
	gutter: PropTypes.oneOf([
		'entityPoster',
		'none',
		'top',
		'topBarEntity',
		'medium',
		'mediumConstant',
		'publicationTitle'
	]),
	ariaLabel: PropTypes.string,
	noPrint: PropTypes.bool,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	tabIndex: PropTypes.number
};

export { Layout, LayoutBlock };
