import React from 'react';
import PropTypes from 'prop-types';
import EntityPropTypes from 'propTypes/entities';

import {
	cardCropTextDefault,
	imageCardSizeDefault
} from 'utils/helpers/entities';

import BaseCard from './BaseCard';

const PromoBlockCard = ({
	className,
	cropTextParams,
	entity,
	keepRatio,
	onFocus,
	params,
	showSubtitle,
	sizeText,
	theme
}) => {
	const {
		title,
		url,
		subTitle,
		thumbnailFile,
		shortText: description
	} = entity;

	return (
		<BaseCard
			{...{
				className,
				cropTextParams,
				description,
				keepRatio,
				onFocus,
				params,
				sizeText,
				theme,
				thumbnailFile,
				title,
				url
			}}
			animation="bottom"
			subTitle={showSubtitle ? subTitle : null}
		/>
	);
};

PromoBlockCard.propTypes = {
	className: PropTypes.string,
	cropTextParams: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string
	}),
	entity: EntityPropTypes.promoBlock.isRequired,
	keepRatio: PropTypes.bool,
	onFocus: PropTypes.func,
	params: PropTypes.shape({
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired
	}),
	showSubtitle: PropTypes.bool,
	sizeText: PropTypes.oneOf(['large']),
	theme: PropTypes.string
};

PromoBlockCard.defaultProps = {
	cropTextParams: cardCropTextDefault,
	params: imageCardSizeDefault.landscape
};

export default PromoBlockCard;
