import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef } from 'react';
import cx from 'classnames';
import AuthButtonContainer from 'containers/AuthButton';
import Navigation from 'components/Navigation';
import Popup from 'components/Popup';

import { NavigationPropType } from 'propTypes/common';

import { PVIContext } from 'contexts/PVIContext';
import UserMenu from '../UserMenu';
import UserButton from '../UserButton';

import styles from './styles.module.scss';

const Menu = ({
	menu,
	onLocationButtonClick,
	locationTitle,
	locationButtonRef,
	onLoginClick,
	popupIsOpen,
	toggleUserMenu,
	closeUserMenu,
	tabIndex,
	changeTabIndex
}) => {
	const { panelActive, pviInstanse } = useContext(PVIContext);
	const authButtonRef = useRef(null);

	const renderUserButton = useCallback(
		(user) =>
			user ? (
				<UserButton
					onClick={toggleUserMenu}
					icon="user"
					title={user.fullName}
					getRef={authButtonRef}
					tabIndex={tabIndex}
				/>
			) : (
				<UserButton
					onClick={onLoginClick}
					title="Войти"
					icon="login"
					tabIndex={tabIndex}
				/>
			),
		[toggleUserMenu, onLoginClick]
	);

	return (
		<div className={styles.menu}>
			{!panelActive && (
				<div className={cx(styles.block, styles.block__desktop)} key={1}>
					<Navigation
						data={menu}
						theme="main"
						parentTabIndex={tabIndex}
						changeParentTabIndex={changeTabIndex}
					/>
				</div>
			)}

			{pviInstanse?.elements ? (
				pviInstanse._elements
			) : (
				<UserButton
					title="Версия для слабовидящих"
					icon="eye"
					tabIndex={tabIndex}
					className={cx(
						'pvi-open',
						styles.block,
						styles.block__left,
						styles.buttonInclusive
					)}
				/>
			)}

			{!panelActive && (
				<div className={cx(styles.block, styles.block__desktop)} key={3}>
					<UserButton
						title={`Ваше местоположение: ${locationTitle}`}
						icon="location"
						onClick={onLocationButtonClick}
						getRef={locationButtonRef}
						tabIndex={tabIndex}
					/>
					<AuthButtonContainer render={renderUserButton} />
				</div>
			)}

			<Popup
				isOpen={popupIsOpen}
				element={authButtonRef.current}
				onClickOutside={closeUserMenu}
			>
				<UserMenu />
			</Popup>
		</div>
	);
};

Menu.propTypes = {
	menu: NavigationPropType.isRequired,
	onLoginClick: PropTypes.func.isRequired,
	onLocationButtonClick: PropTypes.func,
	locationTitle: PropTypes.string,
	locationButtonRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	popupIsOpen: PropTypes.bool,
	toggleUserMenu: PropTypes.func,
	closeUserMenu: PropTypes.func,
	tabIndex: PropTypes.number,
	changeTabIndex: PropTypes.func
};

Menu.defaultProps = {
	onLocationButtonClick: _.noop,
	locationTitle: 'Россия',
	locationButtonRef: _.noop,
	popupIsOpen: false,
	changeTabIndex: _.noop
};

export default Menu;
