import React, { useState, useCallback, useEffect } from 'react';
import dynamic from 'next/dynamic';
import cx from 'classnames';

import Navigation from 'components/Navigation';
import DataLoader from 'components/DataLoader';

import { navigationLinks } from 'utils/helpers/navigation';

import SystemMessageContainer from 'containers/SystemMessage';
import About from './About';
import FooterAddition from './FooterAddition';
import Sidebar from './Sidebar';

import styles from './styles.module.scss';

const FaqModalContainer = dynamic(() => import('containers/modals/Faq'), {
	ssr: false,
	loading: DataLoader
});
const MisprintModal = dynamic(() => import('components/modals/Misprint'), {
	ssr: false
});
const IeNotifyModal = dynamic(() => import('components/modals/IeNotify'), {
	ssr: false
});

const Footer = () => {
	const [isShowFaqModal, setIsShowFaqModal] = useState(false);
	const [isBrowserIe, setIsBrowserIe] = useState(false);

	useEffect(() => {
		setIsBrowserIe(/msie|trident/i.test(window.navigator.userAgent));
	}, []);

	const showFaqModal = useCallback(() => {
		setIsShowFaqModal(true);
	}, []);

	const hideFaqModal = useCallback(() => {
		setIsShowFaqModal(false);
	}, []);

	const [headerTabIndex, setHeaderTabIndex] = useState(0);
	const changeTabIndex = (value) => {
		setHeaderTabIndex(value);
	};

	return (
		<footer
			className={styles.footer}
			tabIndex={0}
			aria-label="Подвал сайта"
			role="navigation"
			aria-hidden="true"
		>
			<div className={styles.main}>
				<div className={styles.content}>
					<div className={cx(styles.row, styles.show_tabletMedium)}>
						<Navigation
							data={navigationLinks.footer}
							theme="footer"
							changeParentTabIndex={changeTabIndex}
							parentTabIndex={headerTabIndex}
						/>
					</div>
					<div className={styles.row}>
						<About />
					</div>
					<div className={cx(styles.row, styles.gutter_increase)}>
						<FooterAddition />
					</div>
				</div>
			</div>
			<div className={styles.sidebar}>
				<Sidebar showFaqModal={showFaqModal} />
			</div>

			{isShowFaqModal && (
				<FaqModalContainer
					hideFaqModal={hideFaqModal}
					isShowFaqModal={isShowFaqModal}
				/>
			)}
			<MisprintModal />
			<SystemMessageContainer />
			{isBrowserIe && <IeNotifyModal />}
		</footer>
	);
};

export default Footer;
