import React from 'react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import cx from 'classnames';
import parse from 'html-react-parser';
import sanitize from 'sanitize-html';
import LazyLoadImage from 'components/LazyLoad/Image';

import { PromoBlockPropType } from 'propTypes/common';
import styles from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

const PushkinDayPromoBlock = ({ promoBlock, spacing }) => {
	const { shortText, text } = promoBlock;

	const imageSrc = `${publicRuntimeConfig?.assetPrefix}images/promoBlocks/pushkinDay/pushkinDay.png`;

	const src = '/s/pushkin/';

	const parsedMainText = parse(sanitize(text));
	const parsedShortText = !!shortText && parse(sanitize(shortText));

	return (
		<a
			href={src}
			rel="noopener"
			aria-label="Промоблок Пушкин Дня"
			className={cx(styles.PromoBlock, styles[`PromoBlock_spacing_${spacing}`])}
		>
			<div className={styles.PromoBlock__ImageContainer}>
				<LazyLoadImage imageSrc={imageSrc}>
					<div
						className={styles.PromoBlock__Image}
						style={{ backgroundImage: `url("${imageSrc}")` }}
					/>
				</LazyLoadImage>
			</div>
			<div className={styles.PromoBlock__Content}>
				<div className={styles.PromoBlock__Title}>Пушкин дня</div>
				<div className={styles.PromoBlock__Text}>{parsedMainText}</div>
				{!!shortText && (
					<div className={styles.PromoBlock__Description}>
						{parsedShortText}
					</div>
				)}
				<div className={styles.corner} />
			</div>
		</a>
	);
};

PushkinDayPromoBlock.propTypes = {
	promoBlock: PromoBlockPropType,
	spacing: PropTypes.oneOf(['entity'])
};

export default PushkinDayPromoBlock;
