import { useRef, useState } from 'react';
import { handleEnterKey } from 'utils/helpers/accessible-events';

export const useTabNavigation = () => {
	const [listItemTabIndex, setListItemTabIndex] = useState(-1);
	const firstListItemRef = useRef(null);

	const handleKeyDown = (event) => {
		handleEnterKey(event, () => {
			event.preventDefault();
			setListItemTabIndex(0);
			firstListItemRef?.current?.focus();
		});
	};

	const handleFocus = (event) => {
		if (event.target === event.currentTarget) {
			setListItemTabIndex(-1);
		}
	};

	const handleBlur = (event) => {
		const { relatedTarget, currentTarget } = event;

		if (!currentTarget.contains(relatedTarget)) {
			setListItemTabIndex(-1);
		}
	};

	return {
		handleBlur,
		handleKeyDown,
		handleFocus,
		firstListItemRef,
		listItemTabIndex
	};
};
