export const handleEnterKey = (event, callback) => {
	if (event.key === 'Enter') {
		if (event.target !== event.currentTarget) {
			return;
		}
		if (event.key === 'Enter') {
			callback();
		}
	}
};

export const handleParentBlur = (event, callback) => {
	const { relatedTarget, currentTarget } = event;
	const isHTML = relatedTarget?.nodeName === 'HTML';
	const isFocusLost = !currentTarget.contains(relatedTarget) || isHTML;

	if (isFocusLost) {
		callback();
	}
};

export const handleParentFocus = (event, callback) => {
	if (event.target === event.currentTarget) {
		callback();
	}
};
