import _ from 'underscore';
import { startOfToday } from 'date-fns';
import getConfig from 'next/config';
import Joi from 'joi';
import {
	api as apiRequest,
	batchApi as batchApiRequest
} from '@culture/common-helpers/request';
import { addCleanCache } from '@culture/common-helpers/params';
import MainTemplate from 'pageTemplates/main';
import { getPushkinDayPromoBlock } from 'utils/helpers/pushkinDayPromoBlock';

const { serverRuntimeConfig } = getConfig();

const Main = (props) => <MainTemplate {...props} />;

const BROADCAST_COUNT = 8;
const NEW_MAIN_PUBLICATIONS_COUNT = 10;
const NEWS_COUNT = 6;

const promoBlockFields = [
	'title',
	'name',
	'thumbnailFile',
	'url',
	'subTitle',
	'shortText'
];

const broadcastFields = [
	'title',
	'name',
	'thumbnailFile',
	'publishDate',
	'institute'
];

const publicationFields = [
	'title',
	'name',
	'shortText',
	'thumbnailFile',
	'type',
	'podcasts'
];

const newsFields = [
	'title',
	'name',
	'thumbnailFile',
	'thumbnailFileCrop',
	'additionalThumbnailFile',
	'rubrics',
	'type',
	'mainRubric',
	'publishDate'
];

const getAdditionalBroadcast = (params, broadcastBlock) =>
	apiRequest(
		'broadcast',
		addCleanCache(
			{
				statuses: ['published'],
				publishDateFrom: startOfToday().toISOString(),
				toDateStart: new Date().toISOString(),
				fields: broadcastFields.join(),
				limit: BROADCAST_COUNT - broadcastBlock.length,
				sort: 'publishDate',
				excludeIds: _(broadcastBlock).pluck('_id'),
				cached: true
			},
			params.cleanCache
		)
	);

const getAdditionalPublications = (params, publicationsBlock) =>
	apiRequest(
		'publications',
		addCleanCache(
			{
				statuses: ['published'],
				types: ['themes', 'articles'],
				fields: publicationFields.join(),
				limit: NEW_MAIN_PUBLICATIONS_COUNT - publicationsBlock.length,
				excludeIds: _(publicationsBlock).pluck('_id'),
				sort: '-publishDate',
				cached: true
			},
			params.cleanCache
		)
	);

const getAdditionalNews = (params, newsBlock) =>
	apiRequest(
		'publications',
		addCleanCache(
			{
				statuses: ['published'],
				types: ['news'],
				fields: newsFields.join(),
				limit: NEWS_COUNT - newsBlock.length,
				excludeIds: _(newsBlock).pluck('_id'),
				sort: '-publishDate',
				cached: true
			},
			params.cleanCache
		)
	);

const getRubricPage = (params) =>
	apiRequest(
		'rubricPages/page',
		addCleanCache(
			{
				rubric: serverRuntimeConfig.rubrics.main,
				blockFields: {
					promoBlocks: promoBlockFields.join(),
					incutPromoBlocks: ['title', 'name', 'url', 'text', 'images'].join(),
					specialProjects: promoBlockFields.join(),
					publications: publicationFields.join(),
					news: newsFields.join(),
					broadcast: broadcastFields.join()
				},
				completeBlocksParams: [
					{
						blockName: 'publicationsMixed',
						blockParams: {
							statuses: ['published'],
							types: ['themes', 'articles'],
							rubricPaths: [serverRuntimeConfig.rubrics.main],
							sort: '-publishDate',
							limit: NEW_MAIN_PUBLICATIONS_COUNT
						}
					},
					{
						blockName: 'news',
						blockParams: {
							statuses: ['published'],
							types: ['news'],
							rubricPaths: [serverRuntimeConfig.rubrics.main],
							sort: '-publishDate',
							limit: NEWS_COUNT
						}
					},
					{
						blockName: 'broadcast',
						blockParams: {
							statuses: ['published'],
							rubricPaths: [serverRuntimeConfig.rubrics.main],
							publishDateFrom: startOfToday(),
							toDateStart: new Date(),
							isLikeCatalog: false,
							limit: BROADCAST_COUNT,
							sort: 'publishDate'
						}
					}
				]
			},
			params.cleanCache
		)
	);

const getCounters = (cleanCache) =>
	apiRequest(
		'counters/entities',
		addCleanCache(
			{
				counters: [
					'movies',
					'lectures',
					'performances',
					'concerts',
					'russiaImages',
					'museums',
					'books',
					'architecture'
				],
				cached: true
			},
			cleanCache
		)
	);

export async function getServerSideProps({ query }) {
	const validationSchema = Joi.object().keys({
		cleanCache: Joi.boolean().failover(false)
	});

	const params = Joi.attempt(query, validationSchema, {
		stripUnknown: true
	});

	const mainRubricPage = await getRubricPage(params);

	let { blocks } = mainRubricPage;

	if (!blocks.broadcast || blocks.broadcast.length < BROADCAST_COUNT) {
		const additionalBroadcast = await getAdditionalBroadcast(
			params,
			blocks.broadcast || []
		);

		const broadcastBlock = blocks.broadcast.concat(additionalBroadcast.items);

		blocks = { ...blocks, broadcast: broadcastBlock };
	}

	const preparedPublicationsMixed = blocks.publicationsMixed
		? blocks.publicationsMixed.filter(
				(publication) => publication.status === 'published'
		  )
		: blocks.publications;

	if (
		!preparedPublicationsMixed ||
		preparedPublicationsMixed.length < NEW_MAIN_PUBLICATIONS_COUNT
	) {
		const additionalPublications = await getAdditionalPublications(
			params,
			preparedPublicationsMixed || blocks.publications || []
		);

		const publicationBlock = preparedPublicationsMixed.concat(
			additionalPublications.items
		);

		blocks = { ...blocks, publications: publicationBlock };
	} else if (preparedPublicationsMixed && preparedPublicationsMixed.length) {
		blocks = { ...blocks, publications: preparedPublicationsMixed };
	}

	if (!blocks.news || blocks.news.length < NEWS_COUNT) {
		const additionalNews = await getAdditionalNews(params, blocks.news || []);

		const newsBlock = blocks.news.concat(additionalNews.items);

		blocks = { ...blocks, news: newsBlock };
	}

	const pushkinDayPromoBlock = await getPushkinDayPromoBlock();
	const [counters] = await batchApiRequest([getCounters(params?.cleanCache)]);

	return {
		props: {
			rubric: mainRubricPage.rubric || null,
			blocks,
			counters,
			pushkinDayPromoBlock: _(pushkinDayPromoBlock).pick('shortText', 'text')
		}
	};
}

export default Main;
