import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

import styles from './styles.module.scss';

const LOGO_TITLE = 'Логотип Культура.РФ. Переход на главную страницу';

const Logo = ({ tabIndex, logoRef }) => (
	<div className={styles.logo}>
		<Link href="/" tabIndex={0}>
			<>
				<a
					ref={logoRef}
					href="/"
					className={styles.link}
					title={LOGO_TITLE}
					tabIndex={tabIndex}
					aria-label={LOGO_TITLE}
				>
					<Icon icon="logo" className={styles.icon} />
				</a>
			</>
		</Link>
	</div>
);

Logo.propTypes = {
	tabIndex: PropTypes.number,
	logoRef: PropTypes.object
};

export default Logo;
