import _ from 'underscore';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import { PVI_NO_STYLES_CLASSNAME } from 'utils/helpers/inclusion';
import styles from './styles.module.scss';

const Button = forwardRef(
	(
		{
			active,
			activeClassName,
			className,
			disabled,
			icon,
			iconClassName,
			iconWrapperClassName,
			onClick,
			reverse,
			theme,
			title,
			titleClassName,
			tooltip,
			type,
			width,
			...props
		},
		ref
	) => {
		const buttonCx = cx(
			styles.Button,
			{
				[styles.Button_active]: active,
				[activeClassName]: active
			},
			styles[`Button_theme_${theme}`],
			styles[`Button_width_${width}`],
			{
				[styles.Button_reverse]: reverse
			},
			{
				[styles.Button_disabled]: disabled
			},
			className
		);

		const iconWrapperCx = cx(styles.Button__IconWrapper, iconWrapperClassName);

		return (
			/* eslint-disable react/button-has-type */
			<button
				type={type}
				className={buttonCx}
				onClick={onClick}
				title={tooltip}
				ref={ref}
				data-cy="button"
				disabled={disabled}
				{...props}
			>
				{!!icon && (
					<span className={iconWrapperCx} data-cy="buttonIconWrapper">
						<Icon
							aria-hidden
							icon={icon}
							className={cx(
								styles.Button__Icon,
								styles[`Button__Icon_${icon}`],
								iconClassName
							)}
							useClassName={PVI_NO_STYLES_CLASSNAME}
						/>
					</span>
				)}
				{!!title && (
					<span
						className={cx(styles.Button__Title, titleClassName)}
						data-cy="buttonTitle"
					>
						{title}
					</span>
				)}
			</button>
			/* eslint-enable */
		);
	}
);

Button.propTypes = {
	active: PropTypes.bool,
	activeClassName: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	iconClassName: PropTypes.string,
	iconWrapperClassName: PropTypes.string,
	onClick: PropTypes.func,
	reverse: PropTypes.bool,
	theme: PropTypes.oneOf(['adaptiveGeometry', 'contrast', 'circleGrayOutline']),
	title: PropTypes.node,
	titleClassName: PropTypes.string,
	tooltip: PropTypes.string,
	type: PropTypes.string,
	width: PropTypes.oneOf(['wide'])
};

Button.defaultProps = {
	active: false,
	onClick: _.noop,
	type: 'button'
};

export default Button;
