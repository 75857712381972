import _ from 'underscore';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BaseCard from 'components/cards/entities/BaseCard';

import styles from './styles.module.scss';

const LinkList = ({ list, type, activeUrl, tabIndex, firstItemRef }) => {
	const listCx = cx(styles.list, styles[`type_${type}`]);

	return (
		<ul className={listCx} role="menu">
			{_(list).map(({ _id, thumbnailFile, title, url }, index) => {
				const itemCx = cx(styles.item, {
					[styles.withCard]: thumbnailFile,
					[styles.active]: activeUrl && activeUrl.startsWith(url)
				});

				return (
					<li className={itemCx} key={_id}>
						{thumbnailFile ? (
							<>
								<div className={styles.subtitle}>Вам может понравиться</div>
								<div className={styles.card}>
									<BaseCard
										{...{
											thumbnailFile,
											title,
											url
										}}
										theme={type !== 'mobile' ? 'navDesktop' : null}
										params={{
											width: 335,
											height: 215
										}}
										preloadImage
										tabIndex={tabIndex}
									/>
								</div>
							</>
						) : (
							<a
								className={styles.link}
								href={url}
								tabIndex={tabIndex}
								role="menuitem"
								ref={index === 0 ? firstItemRef : null}
							>
								<div className={styles.title}>{title}</div>
							</a>
						)}
					</li>
				);
			})}
		</ul>
	);
};

LinkList.propTypes = {
	list: PropTypes.arrayOf(
		PropTypes.shape({
			thumbnailFile: PropTypes.object,
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
			children: PropTypes.array
		})
	).isRequired,
	type: PropTypes.oneOf(['mobile']),
	activeUrl: PropTypes.string,
	tabIndex: PropTypes.number,
	firstItemRef: PropTypes.object
};

export default memo(LinkList);
