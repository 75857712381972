import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { getEntityUrl } from '@culture/utils/helpers/entities';
import { getMicrodata } from '@culture/common-helpers/microdata';
import { parsePrice } from '@culture/common-helpers/events';
import { addQuery } from '@culture/common-helpers/url';

import EntityPropTypes from 'propTypes/entities';

import { cardIcons, imageCardSizeDefault } from 'utils/helpers/entities';

import BaseCard from './BaseCard';

const EventCard = ({
	className,
	cropTextParams,
	entity,
	hasMicrodata,
	instituteId,
	isSpecialEvent,
	location,
	onFocus,
	showDate,
	typeWidth,
	params,
	cardRef
}) => {
	const {
		title,
		thumbnailFile,
		topPlaceTitle,
		isPushkinsCard,
		price,
		tags,
		nearestDate
	} = entity;
	const eventUrl = getEntityUrl(
		isSpecialEvent ? 'specialEvents' : 'events',
		entity
	);
	const eventMicrodata =
		hasMicrodata && (entity?.microdata || getMicrodata(entity, 'events'));
	const preparePrice = price && parsePrice(price);
	const isAccessible = tags?.some((tag) => tag.name === 'dostupnaya-sreda');
	const date = parseISO(nearestDate);
	const cardBadges = [];
	let prepareDate;

	if (showDate && nearestDate) {
		prepareDate = {
			dayMonth: format(date, 'dd MMM', { locale: ruLocale }).replace('.', ''),
			dayWeek: format(date, 'eeeeee HH:mm', { locale: ruLocale })
		};
	}

	if (isPushkinsCard) {
		cardBadges.push(cardIcons.pushkin);
	}

	if (isAccessible) {
		cardBadges.push(cardIcons.accessible);
	}

	let resultUrl;

	if (instituteId || location) {
		const query = instituteId ? { institute: instituteId } : { location };

		resultUrl = addQuery(eventUrl, query);
	} else {
		resultUrl = eventUrl;
	}

	return (
		<BaseCard
			{...{
				className,
				cropTextParams,
				onFocus,
				params,
				thumbnailFile,
				title,
				typeWidth
			}}
			badges={cardBadges}
			date={prepareDate}
			description={topPlaceTitle}
			microdata={eventMicrodata}
			price={preparePrice?.text}
			url={resultUrl}
			cardRef={cardRef}
		/>
	);
};

EventCard.propTypes = {
	className: PropTypes.string,
	cropTextParams: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string
	}),
	entity: EntityPropTypes.event.isRequired,
	hasMicrodata: PropTypes.bool,
	instituteId: PropTypes.number,
	isSpecialEvent: PropTypes.bool,
	location: PropTypes.string,
	onFocus: PropTypes.func,
	showDate: PropTypes.bool,
	typeWidth: PropTypes.string,
	params: PropTypes.shape({
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired
	}),
	cardRef: PropTypes.object
};

EventCard.defaultProps = {
	cropTextParams: {
		title: '2',
		description: '2'
	},
	params: imageCardSizeDefault.landscape
};

export default EventCard;
