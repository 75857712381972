import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Router from 'next/router';
import { resolveHref } from 'next/dist/shared/lib/router/router';
import { PVIContext } from 'contexts/PVIContext';

const NextLink = forwardRef(
	({ href, className, children, linkProps, next, ...props }, ref) => {
		const { panelActive } = useContext(PVIContext);
		if (panelActive) {
			const [, resolvedAs] = resolveHref(Router, href, true);
			return (
				<a href={resolvedAs} className={className} {...props} data-cy="anchor">
					{children}
				</a>
			);
		}
		return !href || next ? (
			<Link href={href || ''} {...linkProps}>
				<a className={className} data-cy="next" ref={ref} {...props}>
					{children}
				</a>
			</Link>
		) : (
			<a
				href={href}
				className={className}
				data-cy="anchor"
				ref={ref}
				{...props}
			>
				{children}
			</a>
		);
	}
);

NextLink.propTypes = {
	href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	next: PropTypes.bool,
	className: PropTypes.string,
	linkProps: PropTypes.object,
	children: PropTypes.node
};

NextLink.defaultProps = {
	next: false,
	linkProps: {}
};

export default NextLink;
