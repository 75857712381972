import _ from 'underscore';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import EntityPropTypes from 'propTypes/entities';

import BaseSlider from 'components/sliders/BaseSlider';

import { useImageType } from '../useImageType';
import PromoBlock from './components/PromoBlock';

const { publicRuntimeConfig } = getConfig();

const MainPromoBlocks = ({ blocks }) => {
	const [imageType] = useImageType(null);
	const [swiper, setSwiper] = useState(null);

	const getSwiper = (swiperInstance) => {
		setSwiper(swiperInstance);
	};

	const setDisabledTabindexDuplicateSlides = (slider) => {
		slider.querySelectorAll('.swiper-slide-duplicate').forEach((slide) => {
			slide.querySelector('a').setAttribute('tabindex', '-1');
			slide.querySelector('a').setAttribute('aria-hidden', 'true');
		});
	};

	const sliderParams = useMemo(
		() => ({
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			loop: true,
			autoplay: { delay: 10000 },
			pagination: {
				clickable: true
			},
			onAfterInit: (slider) => {
				setDisabledTabindexDuplicateSlides(slider.$el[0]);
			}
		}),
		[]
	);

	const scrollToSlideOnFocus = (event) => {
		const swiperSlide = event.target.parentElement;
		const indexSlide = [...swiperSlide.parentElement.children].indexOf(
			swiperSlide
		);

		if (swiper && indexSlide) {
			swiper.autoplay.stop();
			swiper.slideTo(indexSlide);
		}
	};

	return blocks.length > 1 ? (
		<BaseSlider
			key={imageType}
			theme="simple"
			params={sliderParams}
			lazy={publicRuntimeConfig.features.lazy}
			getSwiper={getSwiper}
		>
			{_(blocks).map((block) => (
				<PromoBlock
					key={block._id}
					entity={block}
					imageType={imageType}
					inSlider
					onFocus={scrollToSlideOnFocus}
				/>
			))}
		</BaseSlider>
	) : (
		<PromoBlock key={imageType} entity={blocks[0]} imageType={imageType} />
	);
};

MainPromoBlocks.propTypes = {
	blocks: PropTypes.arrayOf(EntityPropTypes.incutPromoBlock).isRequired
};

export default MainPromoBlocks;
