import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'components/Icon';
import styles from './styles.module.scss';

const Badge = ({ icon, title, ariaLabel }) => (
	<div title={title} className={styles.Badge} aria-label={ariaLabel}>
		<Icon
			icon={icon}
			className={cx(styles.Badge__Icon, 'pvi-no-styles')}
			useClassName="pvi-no-styles"
			aria-hidden="true"
		/>
	</div>
);

Badge.propTypes = {
	ariaLabel: PropTypes.string,
	icon: PropTypes.string,
	title: PropTypes.string
};

export default Badge;
