import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const SocialLinksButton = ({ item }) => {
	const { title, url, icon } = item;

	return (
		<a
			href={url}
			title={title}
			className={styles.SocialLinks__Button}
			target="_blank"
			rel="noopener"
			aria-label={title}
		>
			<Icon
				icon={icon}
				className={cx(
					styles.SocialLinks__Icon,
					styles[`SocialLinks__Icon_${icon}`]
				)}
			/>
		</a>
	);
};

SocialLinksButton.propTypes = {
	item: PropTypes.shape({
		title: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		icon: PropTypes.string.isRequired,
		iconHover: PropTypes.string
	}).isRequired
};

export default SocialLinksButton;
